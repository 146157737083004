<template>
  <div class="agente-tab" key="tab-jornada" v-if="bootstrap">
    <agente-presencia-junta :junta="junta"></agente-presencia-junta>

    <ion-button
      v-if="$store.getters.userHasPermission('directorio')"
      @click="abrirDirectorio"
      expand="block"
    >
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <ion-icon slot="start" :icon="helpBuoyOutline"></ion-icon>

      VER DIRECTORIO</ion-button
    >

    <div class="lista-incidencias" v-if="incidencias.count">
      <div class="banner">
        INCIDENCIAS NOTIFICADAS ({{ incidencias.count }})
      </div>
      <ion-list>
        <ion-item
          v-for="i in incidencias.incidencias"
          :key="i.id"
          class="incidencia"
        >
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="nombre">{{
                i.tipo_incidencia
              }}</ion-col>
            </ion-row>
            <ion-row v-if="i.descripcion">
              <ion-col>
                {{ i.descripcion }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-md class="dato">
                ID:
                <span>#{{ i.id }}</span>
              </ion-col>
              <ion-col size="12" size-md class="dato">
                Estado:
                <span>{{ i.estado_incidencia }}</span>
              </ion-col>

              <ion-col size="12" size-md class="dato">
                Fecha/hora:
                <span>{{
                  i.timestamp.substr(8, 2) +
                  "/" +
                  i.timestamp.substr(5, 2) +
                  " " +
                  i.timestamp.substr(11, 5)
                }}</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4" v-if="i.estado_incidencia_id == 1">
                <ion-button expand="block" @click="enviarFoto(i.id)">
                  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                  <ion-icon slot="start" :icon="attach"></ion-icon>
                  ENVIAR IMAGEN
                </ion-button>
              </ion-col>
              <ion-col size="4" v-if="i.medios.length">
                <ion-button expand="block" @click="verFotos(i)">
                  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                  <ion-icon slot="start" :icon="imagesOutline"></ion-icon>

                  VER {{ i.medios.length }}
                  {{ i.medios.length == 1 ? "IMAGEN" : "IMÁGENES" }}
                </ion-button>
              </ion-col>
              <ion-col size="4" v-if="i.estado_incidencia_id == 1">
                <ion-button
                  expand="block"
                  color="success"
                  @click="marcarResuelta(i.id)"
                >
                  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                  <ion-icon
                    slot="start"
                    :icon="checkmarkCircleOutline"
                  ></ion-icon>

                  MARCAR RESUELTA
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </div>
    <div class="banner nodata" v-else>No se han notificado incidencias.</div>

    <ion-button expand="block" @click="nuevaIncidencia">
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <ion-icon slot="start" :icon="alertCircleOutline"></ion-icon>

      NOTIFICAR INCIDENCIA</ion-button
    >
  </div>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";

import { defineComponent } from "vue";
import {
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  alertController,
} from "@ionic/vue";

import {
  helpBuoyOutline,
  attach,
  imagesOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import AgentePresenciaJunta from "@/components/AgentePresenciaJunta.vue";
import AgenteJornadaReportarIncidencia from "@/views/AgenteJornadaReportarIncidencia.vue";
import { territorios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import cyanGetPhoto from '@/modules/cyanGetPhoto';

export default defineComponent({
  name: "AgenteJornada",
  components: {
    IonButton,
    IonList,
    IonIcon,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    AgentePresenciaJunta,
  },
  setup() {
    return {
      helpBuoyOutline,
      attach,
      imagesOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
    };
  },
  data() {
    return {};
  },
  computed: {
    baseUrl(): string {
      const p = (this as any).$route.params;

      return "/agente/" + p.ambito + "/" + p.junta + "/";
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    bootstrap(): any {
      return store.state.bootstrap[this.junta] || {};
    },

    centroJunta(): any {
      const b = this.bootstrap;
      if (!b || !b.centro || !b.junta) return "";
      return "MESA " + b.junta.nombre + ", " + b.centro.nombre;
    },

    junta(): any {
      return (this as any).$route.params.junta || 0;
    },
    municipio(): string {
      if (this.seed in territorios) {
        const m = territorios[(this as any).seed];
        return m.nombre + ", " + m.nombrePadre;
      }
      return "";
    },

    incidencias(): any {
      return (
        store.state.incidencias[this.junta] || {
          ok: false,
          /* eslint-disable @typescript-eslint/camelcase */
          junta_id: this.junta,
          /* eslint-enable @typescript-eslint/camelcase */
          count: 0,
          incidencias: [],
        }
      );
    },
  },
  methods: {
    async nuevaIncidencia() {
      const modal = await modalController.create({
        component: AgenteJornadaReportarIncidencia,
        cssClass: "fullscreen",
        componentProps: {
          junta: this.junta,
          municipio: this.municipio,
        },
      });
      return modal.present();
    },
    abrirDirectorio() {
      const r = this.$route.path.split("/");
      while (r.length > 4) r.pop();
      r[1] = "dirJuntaA";
      this.$router.replace(r.join("/"));
    },

    async marcarResuelta(idIncidencia: any) {
      const confirmPromise = (): Promise<boolean> => {
        return new Promise((resolve) => {
          alertController
            .create({
              message:
                "¿Está seguro de que quiere mandar esta incidencia como resuelta?",
              buttons: [
                {
                  text: "Sí",
                  handler: () => resolve(true),
                },
                {
                  text: "No",
                  role: "cancel",
                  handler: () => resolve(false),
                },
              ],
            })
            .then((alert) => alert.present());
        });
      };

      if (await confirmPromise()) {
        cyanRequest("agente/closeIncidencia/" + idIncidencia, {
          isModal: true,
          isPost: true,
          needsAuth: true,
        }).then(function (d) {
          if (d.ok) {
            store.commit("storeIncidencia", d.incidencia);
          }
        });
      }
    },

    async enviarFoto(idIncidencia: any) {
      const image = await cyanGetPhoto();

      if (image === false) {
        return;
      }

      cyanRequest("agente/addMedioIncidencia/" + idIncidencia, {
        isPost: true,
        files: { fichero: image },
        isModal: true,
        needsAuth: true,
      }).then(function (d) {
        if (d.ok) {
          store.commit("storeIncidencia", d.incidencia);
        }
      });
    },
    verFotos(incidencia: any) {
      const result = [];
      if (incidencia.medios.length) {
        let counter = 1;
        for (const i of incidencia.medios) {
          result.push({
            src: i.url,
            title: "Imagen " + counter++ + " de " + incidencia.medios.length,
          });
        }
      }

      if (result.length) {
        store.commit("setLightboxImages", result);
        store.commit("setLightboxVisible", true);
      }
    },
  },
});
</script>

<style scoped>
.lista-incidencias {
  margin-top: 1em;
}
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.incidencia {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}
</style>