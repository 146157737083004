<template>
  <ion-page
    :key="'agente-'+junta"
    ><cyan-page-wrapper
      title="Control"
      :backRoute="backRoute"
      nav1="CONTROL"
      :nav2="'Mesa ' + datosJunta.nombre"
      :ambito="centro"
    >

    <ion-segment scrollable v-model="segmentActiveTab">
      <ion-segment-button
        v-for="j in tabs"
        :key="j.id"
        :value="j.id"
        @click="segmentClicked(j.id)"
      >

        <ion-icon :icon="j.icon" />

        <ion-label>{{ j.label }}</ion-label>
      </ion-segment-button>
    </ion-segment>

    <div class="mobileShow">
      <div class="banner nivel1">{{ centroJunta }}</div>
      <div class="banner nivel2">{{ municipio }}</div>
    </div>


    <transition name="fade" mode="out-in">
      <div :key="activeTab">
        <agente-jornada v-if="activeTab == 1" />
        <agente-conteo v-if="activeTab == 2" />
        <agente-actas v-if="activeTab == 3" />
      </div>
    </transition>
    </cyan-page-wrapper></ion-page
  >
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";

import cyanRegions, { territorios } from '@/modules/cyanRegions';
import cyanRequest from "@/modules/cyanRequest";

import { IonPage, IonLabel, IonIcon, IonSegmentButton, IonSegment, alertController } from "@ionic/vue";

import {
  flagOutline,
  mailOpenOutline,
  trophyOutline
} from "ionicons/icons";
import AgenteActas from "./AgenteActas.vue";
import AgenteConteo from "./AgenteConteo.vue";
import AgenteJornada from "./AgenteJornada.vue";

export default defineComponent({
  name: "Agente",
  components: {
    IonLabel,
    IonPage,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    CyanPageWrapper,
    AgenteConteo,
    AgenteJornada,
    AgenteActas,
  },
  setup() {
    return {
      flagOutline,
      mailOpenOutline,
      trophyOutline
    };
  },
  data() {
    return {
      activeTab: 1,
      segmentActiveTab: 1,
      tabs: [
        { id: 1, label: 'Jornada', icon: flagOutline },
        { id: 2, label: 'Recuento', icon: mailOpenOutline, },
        { id: 3, label: 'Actas', icon: trophyOutline },
      ]
    };
  },
  computed: {
    junta(): string {
      return (this as any).$route.params.junta || '??';
    },
    bootstrap(): any {
      return store.state.bootstrap[this.junta] || {};
    },

    centroJunta(): any {
      const b = this.bootstrap;
      if (!b || !b.centro || !b.junta) return "";
      return "MESA " + b.junta.nombre + ", " + b.centro.nombre;
    },

    acj: function(): any {
      const _acj = (this as any).$store.state.allowedCentrosJuntas;
      if (!_acj || !_acj[this.seed]) return {};
      return _acj[this.seed];
    },
    datosJunta: function(): any {
      if (
        !this.acj ||
        !this.acj.datosJuntas ||
        !this.acj.datosJuntas[this.junta]
      )
        return {};
      return this.acj.datosJuntas[this.junta];
    },
    datosCentro: function(): any {
      if (
        !this.acj ||
        !this.acj.datosCentros ||
        !this.acj.datosCentros[this.datosJunta.centro_id]
      )
        return {};
      return this.acj.datosCentros[this.datosJunta.centro_id];
    },
    centro(): string {
      const c = (this.datosCentro.nombre ?? '???');
      if (this.seed in territorios) {
        const m = territorios[(this as any).seed];
				return c + ', ' + m.nombre;
      }
      return c;

    },
    municipio(): string {
      if (this.seed in territorios) {
        const m = territorios[(this as any).seed];
				return  m.nombre + ', ' + m.nombrePadre;
      }
      return '';
    },
    baseUrl(): string {
      const p = (this as any).$route.params;

      return '/agente/' + p.ambito + '/' + p.junta + '/';

    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },

    backRoute() {
      const u = store.state.userData.nivel || "";
      const seed = this.seed as string;

      // Si hay más de una junta en el centro implicado,
      // a la elección de junta

      const acj = store.state.allowedCentrosJuntas[seed];

      if (acj) {
        const j = (this as any).$route.params.junta;
        if (
          acj.datosJuntas &&
          acj.datosJuntas[j] &&
          acj.datosJuntas[j].centro_id &&
          acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id] &&
          acj.mapaCentrosJuntas[acj.datosJuntas[j].centro_id].length >= 2
        )
          return "/agentePickJunta/" + seed + "/" + acj.datosJuntas[j].centro_id;

        // Si hay más de un centro, a la elección de centro

        if (acj.centros.length > 1) return "/agentePickCentro/" + seed;
      } // ACJ
      // Si el usuario tiene nivel municipal, a home; si no, a pickMpio

        return (u.length == cyanRegions.largoFinal || !cyanRegions.divisiones[cyanRegions.largoFinal].largoPadre) ? 
        '/home' : 
        '/agentePicker/' + seed.substring(0,cyanRegions.divisiones[cyanRegions.largoFinal].largoPadre as number);  

    },
  },
  methods: {
    telefonoGuay(t: string) {
      return t.substring(0, 4) + "-" + t.substring(4);
    },
    async segmentClicked(id: number) {
      if(id == 1) {
        cyanRequest('agente/bootstrap/' + this.junta, {
          isModal: false,
          silent: true,
          needsAuth: true
        }).then(function(d: any) {
          if (d.ok) {
            store.commit('storeBootstrap', d)
          }
        });
      } else if (id == 3) {
        const tieneDatos = (typeof store.state.actas[this.junta] !== 'undefined');
        const updaterPromise = cyanRequest('agente/getEstadoActas/' + this.junta, {
            isModal: !tieneDatos,
            silent: tieneDatos,
            needsAuth: true
          }).then(function(d: any) {
            if (d.ok) {
              store.commit('storeActas', d)
            }
          });
        if(!tieneDatos) {
          await updaterPromise;
        }
      }
    }
  },
  watch: {
    async segmentActiveTab(newValue) {

      if (newValue != 1 && !this.bootstrap.abierta) {
        setTimeout(() => this.segmentActiveTab = 1, 10);
        const alert = await alertController.create({
          header: "Error",
          message: 'Debe marcar la apertura de la mesa antes de acceder a otras funciones.',
          buttons: ['OK']
        });
        alert.present();

      } else {
        this.activeTab = newValue;
      }
    }
  }
});
</script>

<style scoped>
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.votante {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}

.dato.cc-ok {
  color: var(--ion-color-success);
}

.dato.cc-rejected {
  color: var(--ion-color-danger);
}

.dato.cc-pending {
  color: var(--ion-color-medium);
}

.dato ion-icon {
  font-size: 110%;
  padding: 0 0 0 3px;
  position: relative;
  top: 3px;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

</style>