<template>
  <div class="agente-presencia">
    <ion-button v-if="!bootstrap.abierta" @click="abrirJunta" expand="block">
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <ion-icon slot="start" :icon="checkmarkCircleOutline"></ion-icon>
      NOTIFICAR CONSTITUCIÓN DE MESA
    </ion-button>
    <div class="banner nivel2" v-else>
      La mesa se marcó como constituida a las
      {{ bootstrap.abierta_en.substr(11, 5) }}.
    </div>
    <ion-button v-if="!bootstrap.cerrada && bootstrap.abierta" @click="cerrarJunta" expand="block">
      <ion-icon slot="start" :icon="checkmarkCircleOutline"></ion-icon>
        NOTIFICAR CIERRE DE MESA
    </ion-button>
    <div class="banner nivel2" v-if="bootstrap.cerrada">
      La mesa se marcó como cerrada a las
      {{ bootstrap.cerrada_en.substr(11, 5) }}.
    </div>
  </div>
</template>

<script lang="ts">
import { IonButton, alertController, loadingController, IonIcon } from "@ionic/vue";
import {checkmarkCircleOutline } from 'ionicons/icons';
import { defineComponent } from "vue";
import store from "@/modules/adaptedStorage";
import cyanRequest from "@/modules/cyanRequest";
import { obtenerUbicacion } from '@/modules/geoAccion';

export default defineComponent({
  name: "AgentePresenciaJunta",
  components: {
    IonButton,
    IonIcon
  },
  setup() {
    return {
      checkmarkCircleOutline
    }
  },
  computed: {
    bootstrap() {
      return store.state.bootstrap[(this as any).junta] || {};
    },
  },
  methods: {
    async abrirJunta() {
      // confirmación

      const j = this.junta;
      const alert = await alertController.create({
        message:
          "Va a indicar que está físicamente presente en la mesa y que ésta ha sido constituida. ¿De acuerdo?",
        buttons: [
          { text: "No", role: "cancel" },
          {
            text: "Sí",
            handler: async function () {
              // Poner toast

              const loading = await loadingController.create({
                message: "Obteniendo ubicación...",
              });
              await loading.present();

              const place = await obtenerUbicacion();

              loading.dismiss();

              let lon,
                lat,
                confirmed = false;

              if (place === false) {
                lon = "";
                lat = "";
                const confirmPromise = (): Promise<boolean> => {
                  return new Promise((resolve) => {
                    alertController
                      .create({
                        message:
                          "No se pudo obtener su ubicación. Puede marcar la mesa como constituida sin indicar ubicación, o reintentarlo más tarde.",
                        buttons: [
                          {
                            text: "Enviar sin ubicación",
                            handler: () => resolve(true),
                          },
                          {
                            text: "Lo reintentaré más tarde",
                            handler: () => resolve(false),
                          },
                        ],
                      })
                      .then((alert) => alert.present());
                  });
                };

                confirmed = await confirmPromise();
              } else {
                confirmed = true;
                lon = (place as any).coords.longitude;
                lat = (place as any).coords.latitude;
              }

              if (confirmed) {
                const result = await cyanRequest("agente/setAbierta/" + j, {
                  needsAuth: true,
                  isPost: true,
                  params: {
                    lon,
                    lat,
                  },
                  isModal: true,
                });

                if (result.ok) {
                  store.commit("storeBootstrap", result);
                } else if (result.alreadyOpen) {
                  const bs = await cyanRequest("agente/bootstrap/" + j, {
                    needsAuth: true,
                    silent: true,
                    isModal: false,
                  });

                  if (bs.ok) store.commit("storeBootstrap", bs);
                }
              }
            },
          },
        ],
      });
      alert.present();
    },
    async cerrarJunta() {
      // confirmación

      const j = this.junta;
      const alert = await alertController.create({
        message:
          "Va a indicar que la votación ha finalizado en esta mesa. ¿De acuerdo?",
        buttons: [
          { text: "No", role: "cancel" },
          {
            text: "Sí",
            handler: async function () {
                const result = await cyanRequest("agente/setCerrada/" + j, {
                  needsAuth: true,
                  isPost: true,
                  params: {
                    lon: null,
                    lat: null,
                  },
                  isModal: true,
                });

                if (result.ok) {
                  store.commit("storeBootstrap", result);
                } else if (result.alreadyOpen) {
                  const bs = await cyanRequest("agente/bootstrap/" + j, {
                    needsAuth: true,
                    silent: true,
                    isModal: false,
                  });

                  if (bs.ok) store.commit("storeBootstrap", bs);
                }

            },
          },
        ],
      });
      alert.present();
    },
  },
  props: ['junta']
});
</script>

<style scoped>
</style>